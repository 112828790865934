import { API, Auth } from "aws-amplify";
import React, { useState } from "react";

import { InputGroup } from "../../components/FormElements";
import { ReactComponent as TolLogo } from '../../logos/TOL_logo_vertical_vert_noir.svg';
import { onError } from "../../libs/errorLib";
import { useAppContext } from "../../libs/contextLib";
import { useFormFields } from "../../libs/hooksLib";
import { useHistory } from "react-router-dom";

function LoginPageButton(props) {
  return (
    <button type="submit" className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green active:bg-green-700 transition duration-150 ease-in-out" 
    disabled={!props.disabledFunc()} onClick={props.onClick}>
      <span className="absolute left-0 inset-y-0 flex items-center pl-3">
        <svg className="h-5 w-5 text-green-500 group-hover:text-green-400 transition ease-in-out duration-150" fill="currentColor" viewBox="0 0 20 20">
          <path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd" />
        </svg>
      </span>
      { props.label }
    </button>
  );
}

function LoginPageFrame(props) {
  return (
<div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full">
        <div>
          <TolLogo />
          <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
            { props.title }
          </h2>
        </div>
        <form className="mt-8">
            { props.form_contents }
          <div className="mt-6">
            { props.button }
          </div>
        </form>
      </div>
    </div>
  );
}

function ErrorPannel(props) {
  return (
    <div className="rounded-md bg-red-100 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <svg className="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fillRule={"evenodd"} d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule={"evenodd"} />
          </svg>
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">
            { props.label }
          </h3>
          <div className="mt-4">
            <div className="-mx-2 -my-1.5 flex">
              <button className="bg-red-50 px-2 py-1.5 rounded-md text-sm font-medium text-red-800 hover:bg-red-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-50 focus:ring-red-600"
                onClick={props.onClick}>
                Resend code
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


export default function Login() {

  const { userHasAuthenticated } = useAppContext();

  const [cognitoUser, setCognitoUser] = useState(false);
  const [loginChallenge, setLoginChallenge] = useState("");
  const [challengeError, setChallengeError] = useState("");

  const [fields, handleFieldChange, setFields] = useFormFields({
    email: "",
    password: "",
    newPassword: "",
    newPassword2: "",
    mfaCode: "",
  });



  const history = useHistory();

  function validateForm() {
    return fields.email.length > 0 && fields.password.length > 0;
  }

  function validateChangePwdForm() {
    return fields.newPassword.length > 0 && 
      fields.newPassword === fields.newPassword2;
  }

  function validateSMS_MFAForm() {
    return fields.mfaCode.length > 0;
  }

  async function doLogin() {
    userHasAuthenticated(true);
    await API.post("broker", "/loggedin");
  }

  async function handleSubmit(event) {
    event.preventDefault();
    
    // reset state
    setFields({"mfaCode": ""});
    setChallengeError("");

    Auth.signIn(fields.email, fields.password)
    .then(async (user) => {
      setCognitoUser(user);
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setLoginChallenge(user.challengeName);
      }
      else if(user.challengeName === 'SMS_MFA') {
        setLoginChallenge(user.challengeName);
      }
      else {
        await doLogin();
      }
    })
    .catch((e) => {
      onError(e);
      if(e.code==="UserNotConfirmedException") {
        // send to login page
        history.push("/confirm");
      }
    });
  }

  async function handleNewPasswordSubmit(event) {
    event.preventDefault();
    Auth.completeNewPassword(
      cognitoUser,          // the Cognito User Object
      fields.newPassword,   // the new password
    ).then(user => {
        if(user.challengeName === 'SMS_MFA') {
          setLoginChallenge(user.challengeName);
        }
        else {
          doLogin();
        }
    }).catch(e => {
      onError(e)
    });
  }

  async function handleSMS_MFASubmit(event) {
    event.preventDefault();
    Auth.confirmSignIn(
      cognitoUser,      // Return object from Auth.signIn()
      fields.mfaCode,   // Confirmation code  
      "SMS_MFA"         // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
    ).then(user => {
        doLogin();
    }).catch(e => {
        onError(e)
        setChallengeError(e.message);
    });
  }

  function renderPasswordChange() {
    return <LoginPageFrame 
        title={"Veuillez choisir un nouveau mot de passe"}
        button={
          <LoginPageButton 
            label={"Changer le mot de passe"}
            onClick={handleNewPasswordSubmit} 
            disabledFunc={validateChangePwdForm} />}
            form_contents={
              <InputGroup fields={[
                  {
                    "label": "Nouveau mot de passe",
                    "id": "newPassword",
                    "type": "password",
                    "field": fields.newPassword,
                    "onChange": handleFieldChange,
                    "placeholder": "Nouveau mot de passe"
                  },
                  {
                    "label": "Confirmer nouveau mot de passe",
                    "id": "newPassword2",
                    "type": "password",
                    "field": fields.newPassword2,
                    "onChange": handleFieldChange,
                    "placeholder": "Confirmer nouveau mot de passe"
                  },
                ]} />
            } 
      />;
  }

  function renderSMS_MFA() {
    return <LoginPageFrame 
      title={"Veuillez entrer votre code MFA"}
      button={
        <LoginPageButton 
          label={"Confirmer le code"}
          onClick={handleSMS_MFASubmit} 
          disabledFunc={validateSMS_MFAForm} />}
          form_contents={
            <React.Fragment>
            { challengeError !== "" ?
              <ErrorPannel label={challengeError} onClick={handleSubmit} />
              : "" }
            <InputGroup fields={[
                {
                  "label": "Code",
                  "id": "mfaCode",
                  "type": "mfaCode",
                  "field": fields.mfaCode,
                  "onChange": handleFieldChange,
                  "placeholder": "Code envoyé à "+cognitoUser.challengeParam.CODE_DELIVERY_DESTINATION,
                }
              ]} />
            </React.Fragment>
          } 
      />;
  }

  function renderLogin() {
    return <LoginPageFrame 
        title={"Connectez-vous à votre compte"}
        button={
          <LoginPageButton
            label={"S'identifier"}
            onClick={handleSubmit} 
            disabledFunc={validateForm} />}
            form_contents={
              <React.Fragment>
              <input type="hidden" name="remember" value="true" />
              <InputGroup fields={[
                {
                  "label": "Adresse e-mail",
                  "id": "email",
                  "type": "email",
                  "field": fields.email,
                  "onChange": handleFieldChange,
                  "placeholder": "Adresse e-mail"
                },
                {
                  "label": "Mot de passe",
                  "id": "password",
                  "type": "password",
                  "field": fields.password,
                  "onChange": handleFieldChange,
                  "placeholder": "Mot de passe"
                },
              ]} />
          </React.Fragment>
        }
      />
  }

  if(loginChallenge==="NEW_PASSWORD_REQUIRED") {
    return renderPasswordChange();
  }
  else if(loginChallenge==="SMS_MFA") {
    return renderSMS_MFA();
  }
  else {
    return renderLogin();
  }
}