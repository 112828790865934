import React, { useEffect, useState } from "react";

import { API } from "aws-amplify";
import { LEAD_STATES } from "../components/LeadsHelper";
import { LoadingLabel } from "../components/Loading";
import { onError } from "../libs/errorLib";
import { useHistory } from "react-router-dom";

export default function Home() {

  const history = useHistory();

  const [dashboardData, setDashboardData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    loadData().then().catch();
  }, []);

  async function loadData() {
    if (isLoading) return;
    setIsLoading(true);

    API.get("broker", "/dashboard")
        .then(rez => {
            setDashboardData(rez);
            setIsLoading(false);
        })
        .catch(error => {
            onError(error)
            setIsLoading(false);
        });
  }

  return (
      <div className="pt-2 pb-6 md:py-6">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <h1 className="text-2xl font-semibold text-gray-900">Dashboard</h1>
        </div>


        <div className="max-w-2xl mx-auto px-4 sm:px-6 md:px-8 mt-7">

        <div className="bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Des commentaires?
            </h3>
            <div className="mt-2 max-w-xl text-sm text-gray-500">
              <p>
                Un poor man's CRM, ça fait la job, mais c'est pas un rich man's CRM.
              </p>
            </div>
            <div className="mt-3 text-sm">
              <a href="https://viridisterra.sharepoint.com/:x:/s/DveloppementdeVTI/EZ8_bP986VBGt28HdvK59XIB5o6h6eXq4XLmbzhfg9BFFw?e=Z7nJcB"
                 target="_blank"
                 className="font-medium text-indigo-600 hover:text-indigo-500">
                Laisser du feedback <span aria-hidden="true">&rarr;</span></a>
            </div>
          </div>
        </div>

        </div>
        {
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 mt-24">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Liste de prospects
          </h3>
          { !dashboardData ? <LoadingLabel /> :  
          <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
            {
                Object.keys(LEAD_STATES).map(function(key, index) {
                  if(!(key in dashboardData["leads"]["counts"]) ||
                      key === "HIDDEN") {
                    return "";
                  }
                  return (
                <div
                    key={key}
                    className="bg-white overflow-hidden shadow rounded-lg">
                  <div className="px-4 py-5 sm:p-6">
                    <dt className="text-sm font-medium text-gray-500 truncate">
                      { LEAD_STATES[key] }
                    </dt>
                    <dd className="mt-1 text-3xl font-semibold text-gray-900">
                      { dashboardData["leads"]["counts"][key] }
                    </dd>
                  </div>
                </div>
                )})
            }
          </dl>
          }
        </div>
        }
      </div>
  );
}
