import { Route, Switch } from "react-router-dom";

import AuthenticatedRoute from "./components/AuthenticatedRoute";
import Home from "./containers/Home";
import Loadable from 'react-loadable';
import NotFound from "./containers/NotFound";
import React from "react";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

function loading() {
  return (<div>Chargement...</div>);
}
const LoadableConfirm = Loadable({
    loader: () => import("./containers/auth/ConfirmAccount"),
    loading: loading
});
const LoadableLogin = Loadable({
    loader: () => import("./containers/auth/Login"),
    loading: loading
});
const LoadableTransactionsList = Loadable({
    loader: () => import("./containers/TransactionList"),
    loading: loading
});
const LoadableTransactionDetails = Loadable({
    loader: () => import("./containers/TransactionDetails"),
    loading: loading
});
const LoadableInvestmentAdd = Loadable({
  loader: () => import("./containers/InvestmentAdd"),
  loading: loading
});
const LoadableInvestmentsList = Loadable({
  loader: () => import("./containers/InvestmentsList"),
  loading: loading
});
const LoadableInvestmentDetails = Loadable({
  loader: () => import("./containers/InvestmentDetails"),
  loading: loading
});
const LoadableLeadsList = Loadable({
  loader: () => import("./containers/LeadsList"),
  loading: loading
});
const LoadableLeadDetails = Loadable({
  loader: () => import("./containers/LeadDetails"),
  loading: loading
});
const LoadableInvestorsList = Loadable({
  loader: () => import("./containers/InvestorList"),
  loading: loading
});
const LoadableInvestorDetails = Loadable({
  loader: () => import("./containers/InvestorDetails"),
  loading: loading
});
const LoadableProfile = Loadable({
  loader: () => import("./containers/Profile"),
  loading: loading
});


export default function Routes() {
  return (
    <Switch>
      <AuthenticatedRoute exact path="/" component={Home} />
      <AuthenticatedRoute exact path="/investments/add/:uid" component={LoadableInvestmentAdd} />
      <AuthenticatedRoute exact path="/investments" component={LoadableInvestmentsList} />
      <AuthenticatedRoute exact path="/investment/:uid/:iid" component={LoadableInvestmentDetails} />
      <AuthenticatedRoute exact path="/leads" component={LoadableLeadsList} />
      <AuthenticatedRoute exact path="/lead/:lid" component={LoadableLeadDetails} />
      <AuthenticatedRoute exact path="/transactions" component={LoadableTransactionsList} />
      <AuthenticatedRoute exact path="/transaction/:uid/:tid" component={LoadableTransactionDetails} />
      <AuthenticatedRoute exact path="/investors" component={LoadableInvestorsList} />
      <AuthenticatedRoute exact path="/investors/:uid" component={LoadableInvestorDetails} />
      <AuthenticatedRoute exact path="/profile" component={LoadableProfile} />
      <UnauthenticatedRoute exact path="/login" component={LoadableLogin} />
      <UnauthenticatedRoute exact path="/confirm" component={LoadableConfirm} />
        {/* Finally, catch all unmatched routes */}
        <Route component={NotFound} />
    </Switch>
  );
}