
export const LEAD_STATES = {
    "OPEN_NEW": "New",
    "OPEN_SCHEDULED": "Call scheduled",
    "OPEN_SCHEDULED_CANCELLED": "Call cancelled",
    "OPEN_CONSIDERATION": "Under consideration by investor",
    "OPEN_DISPATCHED": "Dispatched to external broker",
    "OPEN_COMMITMENT": "Commitment to move forward",
    "OPEN_PAPERWORK_DONE": "Paperwork done",
    "CLOSED_INVESTMENT_CREATED": "Closed investment created",
    "CLOSED_LOSS": "Closed loss",
    "OTHER": "Other",
    "HIDDEN": "Hidden",
}