import React, {useEffect, useState} from "react";

import {AppContext} from "./libs/contextLib";
import {Auth} from "aws-amplify";
import ErrorBoundary from "./components/ErrorBoundary";
import Login from "./containers/auth/Login";
import NavItem from "./components/NavItem";
import Routes from "./Routes";
import {ReactComponent as TolLogo} from './logos/TOL_logo_horizontal_blanc.svg';
import {onError} from "./libs/errorLib";
import {useHistory} from "react-router-dom";

function App() {
    const [isAuthenticating, setIsAuthenticating] = useState(true);
    const [isAuthenticated, userHasAuthenticated] = useState(false);
    const [selectedSection, setSelectedSection] = useState("");

    const history = useHistory();

    useEffect(() => {
        onLoad().then().catch();
    }, []);

    async function onLoad() {
        try {
            await Auth.currentSession();
            userHasAuthenticated(true);
        } catch (e) {
            if (e !== 'No current user') {
                onError(e)
            }
        }

        setIsAuthenticating(false);
    }

    async function handleLogout() {
        await Auth.signOut({global: true});
        userHasAuthenticated(false);
    }

    async function globalSignOut() {
        try {
            await Auth.signOut({global: true});
            userHasAuthenticated(false);
        } catch (error) {
            console.error('error signing out: ', error);
        }
    }

    function displayNotIng() {
        return (
            !isAuthenticated
                ?
                <ErrorBoundary>
                    <AppContext.Provider value={{isAuthenticated, userHasAuthenticated}}>
                        <Login/>
                    </AppContext.Provider>
                </ErrorBoundary>
                :
                rtnFrame()
        );
    }

    function handleMenuClick(section) {
        setSelectedSection(section);
        history.push("/" + section);
    }

    function rtnFrame() {
        return (
            <div className="h-screen flex overflow-hidden bg-gray-100">
                <div className="hidden md:flex md:flex-shrink-0">
                    <div className="flex flex-col w-64">
                        {
                            // Sidebar component, swap this element with another sidebar if you like
                        }
                        <div className="flex flex-col h-0 flex-1 bg-green-800">
                            <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
                                <div className="flex items-center flex-shrink-0 px-4">
                                    <TolLogo/>
                                </div>
                                <nav className="mt-5 flex-1 px-2 bg-green-800 space-y-1">
                                    <NavItem icon={"home"} label={"Dashboard"}
                                             isSelected={selectedSection === ""}
                                             onClick={() => handleMenuClick("")}/>
                                    <NavItem icon={"inbox-in"} label={"Prospects"}
                                             isSelected={selectedSection === "leads"}
                                             onClick={() => handleMenuClick("leads")}/>
                                    <NavItem icon={"users"} label={"Investisseurs"}
                                             isSelected={selectedSection === "investors"}
                                             onClick={() => handleMenuClick("investors")}/>
                                    <NavItem icon={"money"} label={"Investissements"}
                                             isSelected={selectedSection === "investments"}
                                             onClick={() => handleMenuClick("investments")}/>
                                    {
                                        (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
                                            ? <React.Fragment>
                                                <NavItem icon={"cart"} label={"Transactions"}
                                                         isSelected={selectedSection === "transactions"}
                                                         onClick={() => handleMenuClick("transactions")}/>
                                                <NavItem icon={"inbox"} isSelected={false} label={"Documents"}/>
                                            </React.Fragment>
                                            : ""
                                    }
                                    <NavItem icon={"cog"} label={"Profil"}
                                             onClick={() => handleMenuClick("profile")}/>
                                    <NavItem icon={"logout"} isSelected={false} label={"Logout"}
                                             onClick={handleLogout}/>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col w-0 flex-1 overflow-hidden">
                    <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabIndex="0">
                        {
                            <ErrorBoundary>
                                <AppContext.Provider value={{isAuthenticated, userHasAuthenticated}}>
                                    <Routes/>
                                </AppContext.Provider>
                            </ErrorBoundary>
                        }
                    </main>
                </div>
            </div>
        );
    }

    return (
        isAuthenticating ?
            (<div></div>)
            : displayNotIng()
    );
}

export default App;
