import React from "react";

export function DropDown(props) {
    let disabled = false;
    if(props.disabled) {
        disabled = props.disabled;
    }
    return (
        <div className="mt-4">
            <select
                className="mt-1 shadow-sm focus:ring-green-500 py-1 px-3 focus:border-green-500 block w-full focus:outline-none sm:text-sm border border-gray-300 rounded-md"
                id={props.elementId}
                name={props.elementId}
                value={props.field}
                onChange={props.onChange}
                disabled={disabled}
            >
                { props.options.map((item, idx) =>
                    <option key={idx} value={item.value} disabled={item.disabled}>{item.label}</option>
                )}
            </select>
        </div>
    );
}

export function DropDownWithLabel(props) {
    let disabled = false;
    if(props.disabled) {
        disabled = props.disabled;
    }
    return (
        <div className="mt-4">
            <label htmlFor={props.elementId} className="block text-sm font-medium text-gray-700">
                {props.label}
            </label>
            <select 
                className="mt-1 shadow-sm focus:ring-green-500 py-1 px-3 focus:border-green-500 block w-full focus:outline-none sm:text-sm border border-gray-300 rounded-md"
                id={props.elementId}
                name={props.elementId} 
                value={props.field}
                onChange={props.onChange}
                disabled={disabled}
            >
                { props.options.map((item, idx) => 
                    <option key={idx} value={item.value} disabled={item.disabled}>{item.label}</option>
                )}
            </select>
        </div>
    );
}

export function Input(props) {
    let fieldType = "text";
    if(props.fieldType) {
        fieldType = props.fieldType;
    }
    let disabled = false;
    if(props.disabled) {
        disabled = props.disabled;
    }
    return (
        <div className="mt-4">
            <div className="mt-1">
                <input
                    type={fieldType}
                    className="shadow-sm appearance-none focus:ring-green-500 py-1 px-3 focus:border-green-500 block w-full focus:outline-none sm:text-sm border border-gray-300 rounded-md"
                    id={props.elementId}
                    value={props.field}
                    onChange={props.onChange}
                    placeholder={props.placeholder}
                    disabled={disabled}
                />
            </div>
        </div>
    );
}

export function InputWithLabel(props) {
    let fieldType = "text";
    if(props.fieldType) {
        fieldType = props.fieldType;
    }
    let disabled = false;
    if(props.disabled) {
        disabled = props.disabled;
    }
    return (
        <div className="mt-4">
            <label htmlFor={props.elementId} className="block text-sm font-medium text-gray-700">
                {props.label}
            </label>
            <div className="mt-1">
                <input 
                    type={fieldType}
                    className="shadow-sm appearance-none focus:ring-green-500 py-1 px-3 focus:border-green-500 block w-full focus:outline-none sm:text-sm border border-gray-300 rounded-md"
                    id={props.elementId} 
                    value={props.field}
                    onChange={props.onChange}
                    placeholder={props.placeholder}
                    disabled={disabled}
                />
            </div>
        </div>
    );
}

export function InputGroup(props) {
    return (
        <div className="rounded-md shadow-sm">
        { props.fields.map((field, index) => {
            let roundCss;
            if(index === 0) roundCss="rounded-t-md";
            else            roundCss="rounded-b-md";

            return (
                <div key={index}>
                <input required 
                    className={roundCss + " appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"}
                    aria-label={field.label} id={field.id} type={field.type}
                    value={field.field}
                    onChange={field.onChange}
                    placeholder={field.placeholder} />
                </div>
            );
        })
        }
      </div>
    );
}

export function ToggleWithRightLabel(props) {
    let bgColor = props.on ? "bg-green-600" : "bg-gray-200";
    let translate = props.on ? "translate-x-5" : "translate-x-0";
    return (
        <div className="flex items-center">
        <button 
            type="button"
            aria-pressed="false" 
            aria-labelledby={props.id} 
            className={bgColor+" relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"}
            onClick={props.onClick}
        >
            <span className="sr-only">Use setting</span>
            <span aria-hidden="true" className={translate+" inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"}></span>
        </button>
        <span className="ml-3" id={props.id}>
            <span className="text-sm font-medium text-gray-900">{props.label} </span>
            <span className="text-sm text-gray-500">{props.sublabel}</span>
        </span>
        </div>
    );
}

// https://tailwindui.com/components/application-ui/forms/radio-groups
export function ToggleListWithDescription(props) {
    return (
    <React.Fragment>
    { props.header && 
        <span className="block text-sm font-medium text-gray-700 mb-2">
            {props.header}
        </span>
    }
    <fieldset>
    <div className="bg-white rounded-md -space-y-px">
        {
        props.elements.map((elem, idx) => {
            let selected = props.field === elem.value;
            return (
            <div
                key={idx}
                onClick={() => props.onChange({
                        'target': {
                            'id': props.elementId,
                            'value': elem.value
                        }
                    })}
                className={"relative border p-4 flex "+
                    (idx === 0 ? " rounded-tl-md rounded-tr-md " : "")+
                    (idx === props.elements.length-1 ? " rounded-bl-md rounded-br-md " : "")+
                    (selected ? "bg-green-50 border-green-500 z-10" : "border-gray-200")}>

                <div className="flex items-center h-5">
                    <input
                        id={"settings-option-"+idx}
                        type="radio"
                        className="focus:ring-green-500 h-4 w-4 text-green-600 cursor-pointer border-gray-300"
                        checked={selected}
                    />
                </div>
                <label htmlFor={"settings-option-"+idx} className="ml-3 flex flex-col cursor-pointer">
                    <span className={"block text-sm font-medium "+(selected ? "text-green-900" : "text-gray-900")}>
                        { elem.title }
                    </span>
                    <span className={"block text-sm "+(selected ? "text-green-700" : "text-gray-500")}>
                        { elem.desc }
                    </span>
                </label>
            </div>);
        })}
    </div>
    </fieldset>
    </React.Fragment>
    );
}